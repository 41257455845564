<template>
    <div class="card mb-0">
        <div class="card-header">
            <table style="width: 100%;">
                <tr>
                    <td>{{ title }}</td>
                    <td style="font-weight: normal;text-align: right;">
                        <template v-if="data.is_trashed">
                            ({{ $i18n.t('translations.Deleted') }})
                        </template>
                        <template v-else-if="data.is_external">
                            ({{ $i18n.t('translations.Source') }}: {{ data.source_application }})
                        </template>
                        <template v-else-if="!state.readonly">
                            <template v-for="(action,index) in actions">
                                <template v-if="!action.hidefield || !data[action.hidefield]">
                                    <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                                  v-on:update-customer="$emit('update-customer', action)"
                                                  v-if="action.type === 'BUTTON' && action.placement === 'CUSTOMERCARDHEADERRIGHT'"/>
                                </template>
                            </template>
                        </template>
                    </td>
                </tr>
            </table>
        </div>
        <div class="card-body">
            <table class="table table-borderless table-sm mb-0">
                <tbody>
                <tr v-if="data.full_name">
                    <td class="p-0" style="width: 120px;"><b>{{ $i18n.tc('translations.Contact', 1) }}</b></td>
                    <td class="p-0">{{ data.full_name }}</td>
                </tr>
                <tr v-if="data.company">
                    <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Company') }}</b></td>
                    <td class="p-0">{{ data.company }}</td>
                </tr>
                <tr v-if="data.street || data.city || data.country">
                    <td class="p-0" style="width: 120px; vertical-align: top;"><b>{{ $i18n.t('translations.Address') }}</b></td>
                    <td class="p-0">
                        <span v-if="data.street_and_number">{{ data.street_and_number }}<br></span>
                        <span v-if="data.postal_and_city">{{ data.postal_and_city }}<br></span>
                        <span v-if="data.country">{{ data.country }}</span>
                    </td>
                </tr>
                <tr v-if="data.vat">
                    <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.VAT') }}</b></td>
                    <td class="p-0">{{ data.vat }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction.vue";

export default {
    name: "CustomerCard",
    components: {
        ButtonAction,
    },
    props: ['title', 'data', 'actions', 'state'],
}
</script>

<style scoped>
</style>
